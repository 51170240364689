<template>
  <div class="table-responsive">
    <table class="table table-hover text-nowrap">
      <tbody>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">css</a></td>
          <td>info about file</td>
          <td>10 minutes ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">font</a></td>
          <td>info about file</td>
          <td>18 minutes ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">resources</a></td>
          <td>info about file</td>
          <td>18 minutes ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">dist</a></td>
          <td>info about file</td>
          <td>8 minutes ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">img</a></td>
          <td>info about file</td>
          <td>6 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-folder"></i></td>
          <td><a href="#" class="vb__utils__link">js</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">Specification.md</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">Readme.md</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">index.html</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">text.html</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">gulp.js</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">autoload.php</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">bower.js</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
        <tr>
          <td style="width: 25px"><i class="fe fe-file"></i></td>
          <td><a href="#" class="vb__utils__link">__exclude.js</a></td>
          <td>info about file</td>
          <td>3 month ago</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
